<template>
  <div class="contact" id="contact">

        <div class="bg-red-100">
          <div class="max-w-7xl mx-auto py-16 px-4 sm:px-6 lg:py-24 lg:px-8">
            <div class="divide-y-2 divide-gray-200">
              <div class="lg:grid lg:grid-cols-3 lg:gap-8">
                <h2 class="text-2xl font-extrabold text-red-400 sm:text-3xl">
                  Contact us
                </h2>
                <div class="mt-8 grid grid-cols-1 gap-12 sm:grid-cols-2 sm:gap-x-8 sm:gap-y-12 lg:mt-0 lg:col-span-2">
                  <div>
                    <h3 class="text-lg leading-6 font-medium text-red-400">
                      Email
                    </h3>
                    <dl class="mt-2 text-base text-gray-600">
                      <div>
                        <dt class="sr-only">
                          Email
                        </dt>
                        <dd>
                          hello@beefbytes.com
                        </dd>
                      </div>
                    </dl>
                  </div>
                  <div>
                    <h3 class="text-lg leading-6 font-medium text-red-400">
                      Discord
                    </h3>
                    <dl class="mt-2 text-base text-gray-600">
                      <div>
                        <dt class="sr-only">
                          Discord
                        </dt>
                        <dd>
                          beefbytes.com/discord
                        </dd>
                      </div>
                    </dl>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>



  </div>
</template>

<script>


</script>

<style scoped>

</style>

<template>
  <div class="portfolio" id="portfolio">


    <!-- This example requires Tailwind CSS v2.0+ -->
    <div class="relative bg-white pt-16 pb-20 px-4 sm:px-6 lg:pt-16 lg:pb-20 lg:px-8">
      <div class="absolute inset-0">
        <div class="bg-white h-1/3 sm:h-2/3"></div>
      </div>
      <div class="relative max-w-7xl mx-auto">
        <div class="text-center">
          <h2 class="text-3xl tracking-tight font-extrabold text-red-400 sm:text-4xl">
            Portfolio
          </h2>
          <p class="mt-3 max-w-2xl mx-auto text-xl text-gray-600 sm:mt-4">
            All of the projects listed below are being actively developed and maintained!
          </p>
        </div>
        <div class="mt-12 max-w-lg mx-auto grid gap-5 lg:grid-cols-3 lg:max-w-none ">

          <div class="flex flex-col rounded-lg shadow-lg overflow-hidden group transition duration-200 transform hover:-translate-y-3">
            <div class="flex-shrink-0">
              <img class="h-48 w-full object-cover" src="@/assets/singlbit.webp" alt="">
            </div>
            <div class="flex-1 bg-gradient-to-r from-red-50 to-red-100 p-6 flex flex-col justify-between">
              <div class="flex-1">
                <p class="text-sm font-medium space-x-1">
                  <span class="inline-flex items-center justify-center px-2 py-1 text-xs font-bold leading-none text-white bg-red-500 rounded">Vue</span>
                  <span class="inline-flex items-center justify-center px-2 py-1 text-xs font-bold leading-none text-white bg-red-500 rounded">Docker</span>
                </p>
                <a href="https://singlbit.com/" target="_blank" class="block mt-2">
                  <p class="text-xl font-semibold text-red-500">
                    Singlbit
                  </p>
                  <p class="mt-3 text-base text-gray-600">
                    Singlbit is a server host with focus on hardware performance and quality. The website was built using Vue.js. WHMCS is used as a backend for processing payments and assigning servers to clients.
                  </p>
                  <span href="#" class="mt-2 whitespace-nowrap inline-flex items-center justify-center py-1 border border-transparent rounded-md font-bold text-s text-red-500">
                    Singlbit.com
                  </span>
                </a>
              </div>
            </div>
          </div>

          <div class="flex flex-col rounded-lg shadow-lg overflow-hidden group transition duration-200 transform hover:-translate-y-3">
            <div class="flex-shrink-0">
              <img class="h-48 w-full object-cover" src="@/assets/gmodhq.webp" alt="">
            </div>
            <div class="flex-1 bg-gradient-to-r from-red-50 to-red-100 p-6 flex flex-col justify-between">
              <div class="flex-1">
                <p class="text-sm font-medium space-x-1">
                  <span class="inline-flex items-center justify-center px-2 py-1 text-xs font-bold leading-none text-white bg-red-500 rounded">Preact</span>
                  <span class="inline-flex items-center justify-center px-2 py-1 text-xs font-bold leading-none text-white bg-red-500 rounded">Typescript</span>
                  <span class="inline-flex items-center justify-center px-2 py-1 text-xs font-bold leading-none text-white bg-red-500 rounded">Docker</span>
                </p>
                <a href="https://stats.gmodhq.com/" target="_blank" class="block mt-2">
                  <p class="text-xl font-semibold text-red-500">
                    GmodHQ
                  </p>
                  <p class="mt-3 text-base text-gray-600">
                    GmodHQ is a network of Garry's Mod servers. The website is built using Preact with API written in Typescript for advanced leaderboards and user profiles. Inventories and user statistics are updated in real time using Redis.
                  </p>
                  <span href="#" class="mt-2 whitespace-nowrap inline-flex items-center justify-center py-1 border border-transparent rounded-md font-bold text-s text-red-500">
                    GmodHQ.com
                  </span>
                </a>
              </div>
            </div>
          </div>

          <div class="flex flex-col rounded-lg shadow-lg overflow-hidden group transition duration-200 transform hover:-translate-y-3">
            <div class="flex-shrink-0">
              <img class="h-48 w-full object-cover" src="@/assets/cowcraft.webp" alt="">
            </div>
            <div class="flex-1 bg-gradient-to-r from-red-50 to-red-100 p-6 flex flex-col justify-between">
              <div class="flex-1">
                <p class="text-sm font-medium space-x-1">
                  <span class="inline-flex items-center justify-center px-2 py-1 text-xs font-bold leading-none text-white bg-red-500 rounded">Vue</span>
                  <span class="inline-flex items-center justify-center px-2 py-1 text-xs font-bold leading-none text-white bg-red-500 rounded">Docker</span>
                </p>
                <a href="https://cowcraft.net/" target="_blank" class="block mt-2">
                  <p class="text-xl font-semibold text-red-500">
                    CowCraft
                  </p>
                  <p class="mt-3 text-base text-gray-600">
                    CowCraft is a network of minecraft servers. It was originally founded back in 2012 and has peaked at over 1200 concurrent players online. In total, around 2 million unique users have played CowCraft.
                  </p>
                  <span href="#" class="mt-2 whitespace-nowrap inline-flex items-center justify-center py-1 border border-transparent rounded-md font-bold text-s text-red-500">
                    CowCraft.net
                  </span>
                </a>
              </div>
            </div>
          </div>



        </div>


        <div class="mt-6 max-w-lg mx-auto grid gap-5 lg:grid-cols-3 lg:max-w-none">




          <div class="flex flex-col rounded-lg shadow-lg overflow-hidden group transition duration-200 transform hover:-translate-y-3">
            <div class="flex-shrink-0">
              <img class="h-48 w-full object-cover" src="@/assets/dockerplex.webp" alt="">
            </div>
            <div class="flex-1 bg-gradient-to-r from-red-50 to-red-100 p-6 flex flex-col justify-between">
              <div class="flex-1">
                <p class="text-sm font-medium space-x-1">
                  <span class="inline-flex items-center justify-center px-2 py-1 text-xs font-bold leading-none text-white bg-red-500 rounded">Plex</span>
                  <span class="inline-flex items-center justify-center px-2 py-1 text-xs font-bold leading-none text-white bg-red-500 rounded">Docker</span>
                </p>
                <a href="https://dockerplex.org/" target="_blank" class="block mt-2">
                  <p class="text-xl font-semibold text-red-500">
                    DockerPlex
                  </p>
                  <p class="mt-3 text-base text-gray-600">
                    DockerPlex is dockerized Plex media center with additional services such as sonarr, radarr, transmission and more. These services help automate sorting and tracking of torrents for the media center.
                  </p>
                  <span href="#" class="mt-2 whitespace-nowrap inline-flex items-center justify-center py-1 border border-transparent rounded-md font-bold text-s text-red-500">
                    DockerPlex.org
                  </span>
                </a>
              </div>
            </div>
          </div>

          <div class="flex flex-col rounded-lg shadow-lg overflow-hidden group transition duration-200 transform hover:-translate-y-3">
            <div class="flex-shrink-0">
              <img class="h-48 w-full object-cover" src="@/assets/birzuvet.webp" alt="">
            </div>
            <div class="flex-1 bg-gradient-to-r from-red-50 to-red-100 p-6 flex flex-col justify-between">
              <div class="flex-1">
                <p class="text-sm font-medium space-x-1">
                  <span class="inline-flex items-center justify-center px-2 py-1 text-xs font-bold leading-none text-white bg-red-500 rounded">Wordpress</span>
                  <span class="inline-flex items-center justify-center px-2 py-1 text-xs font-bold leading-none text-white bg-red-500 rounded">Docker</span>
                </p>
                <a href="https://birzuvet.lt/" target="_blank" class="block mt-2">
                  <p class="text-xl font-semibold text-red-500">
                    Birzuvet
                  </p>
                  <p class="mt-3 text-base text-gray-600">
                    Birzuvet is an animal clinic based in Lithuania. The website was built using dockerized wordpress. Website is optimized for performance and SEO while using minimal amount of plugins.
                  </p>
                  <span href="#" class="mt-2 whitespace-nowrap inline-flex items-center justify-center py-1 border border-transparent rounded-md font-bold text-s text-red-500">
                    Birzuvet.lt
                  </span>
                </a>
              </div>
            </div>
          </div>

          <div class="flex flex-col rounded-lg shadow-lg overflow-hidden group transition duration-200 transform hover:-translate-y-3">
            <div class="flex-shrink-0">
              <img class="h-48 w-full object-cover" src="@/assets/vizgis.webp" alt="">
            </div>
            <div class="flex-1 bg-gradient-to-r from-red-50 to-red-100 p-6 flex flex-col justify-between">
              <div class="flex-1">
                <p class="text-sm font-medium space-x-1">
                  <span class="inline-flex items-center justify-center px-2 py-1 text-xs font-bold leading-none text-white bg-red-500 rounded">Wordpress</span>
                  <span class="inline-flex items-center justify-center px-2 py-1 text-xs font-bold leading-none text-white bg-red-500 rounded">Docker</span>
                </p>
                <a href="https://vizgis.lt/" target="_blank" class="block mt-2">
                  <p class="text-xl font-semibold text-red-500">
                    Vizgis
                  </p>
                  <p class="mt-3 text-base text-gray-600">
                    Vizgis is an animal shelter based in Lithuania. The website was made using dockerized wordpress. It's currently being actively developed.
                  </p>
                  <span href="#" class="mt-2 whitespace-nowrap inline-flex items-center justify-center py-1 border border-transparent rounded-md font-bold text-s text-red-500">
                    Vizgis.lt
                  </span>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>


</script>

<style scoped>

</style>

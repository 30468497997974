<!-- This example requires Tailwind CSS v2.0+ -->
<template>
  <header class="bg-white shadow-lg">
    <nav class="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8" aria-label="Top">
      <div class="w-full py-6 flex items-center justify-between border-b border-red-200 lg:border-none">
        <div class="flex items-center">
          <a href="#">
            <span class="sr-only">BeefBytes logo</span>
            <img class="h-7 w-auto" src="@/assets/logo.svg" alt="" />
          </a>
        </div>

        <div class="hidden ml-10 space-x-8 lg:block items-center justify-center">

          <router-link :to="link.href" v-for="link in navigation" :key="link.name">
            <a href="#" class="text-base font-bold text-gray-600 hover:text-red-500">
              {{ link.name }}
            </a>
          </router-link>

        </div>

        <div class="ml-10 space-x-4">
          <router-link to="/#contact">
            <a href="#" class="inline-block bg-red-100 py-2 px-2 sm:px-4 border border-transparent rounded-md text-sm sm:text-base font-bold text-red-500 hover:bg-red-200">Contact us</a>
          </router-link>
        </div>
      </div>
      <div class="py-4 flex flex-wrap justify-center space-x-6 lg:hidden">

        <router-link :to="link.href" v-for="link in navigation" :key="link.name">
          <a href="#" class="text-base font-medium text-gray-600 hover:text-red-500">
            {{ link.name }}
          </a>
        </router-link>

      </div>
    </nav>
  </header>
</template>

<script>
const navigation = [
  { name: 'About us', href: '/#about' },
  { name: 'Portfolio ', href: '/#portfolio' },
]

export default {
  setup() {
    return {
      navigation,
    }
  },
}
</script>

<template>
  <div class="home">

        <Hero />
        <About />
        <Portfolio />
        <Contact />


  </div>
</template>

<script>

import Hero from '@/components/Hero.vue'
import Portfolio from '@/components/Portfolio.vue'
import About from '@/components/About.vue'
import Contact from '@/components/Contact.vue'

export default{

  mounted() {
    window.scrollTo(0, 0)
  },

  components:{
    Hero,
    About,
    Portfolio,
    Contact
  }
}

</script>

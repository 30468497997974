<!-- This example requires Tailwind CSS v2.0+ -->
<template >
  <div class="py-16 bg-white" id="about">
    <div class="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
      <div class="lg:text-center">
        <p class="mt-2 text-3xl leading-8 font-extrabold tracking-tight text-red-400 sm:text-4xl">
          About us
        </p>
        <p class="mt-4 max-w-2xl text-xl text-gray-600 lg:mx-auto">
          BeefBytes is fairly new but our team has over a decade of experience in the field. We thrive to provide the best service.
        </p>
      </div>

      <div class="mt-10">
        <dl class="space-y-10 md:space-y-0 md:grid md:grid-cols-2 md:gap-x-8 md:gap-y-10">
          <div v-for="feature in features" :key="feature.name" class="relative">
            <dt>
              <div class="absolute flex items-center justify-center h-12 w-12 rounded-md bg-gradient-to-r from-red-300 to-red-400 text-white">
                <component :is="feature.icon" class="h-6 w-6" aria-hidden="true" />
              </div>
              <p class="ml-16 text-lg leading-6 font-medium text-red-500">{{ feature.name }}</p>
            </dt>
            <dd class="mt-2 ml-16 text-base text-gray-600">
              {{ feature.description }}
            </dd>
          </div>
        </dl>
      </div>
    </div>
  </div>
</template>

<script>
import { UserGroupIcon, CloudIcon, LightningBoltIcon, ChartPieIcon } from '@heroicons/vue/solid'

const features = [
  {
    name: 'Cloud solutions',
    description:
      'We deliver professional cloud services for private, public and hybrid cloud environments. ',
    icon: CloudIcon,
  },
  {
    name: 'IT Analytics',
    description:
      'We help you to drive your business excellence by optimizing operational, development and delivery processes. ',
    icon: ChartPieIcon,
  },
  {
    name: ' DevOps',
    description:
      'We provide DevOps as a service & consulting for all kinds of organizations from startup to enterprise. ',
    icon: LightningBoltIcon,
  },
  {
    name: 'SEO',
    description:
      'We help your business growth by optimizing SEO, creating targeted ad campaigns and using best practices when it comes to code. ',
    icon: UserGroupIcon,
  },
]

export default {
  setup() {
    return {
      features,
    }
  },
}
</script>
